import { get, toJS } from "mobx";

// ==== Stores ====
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";
import OptionsStore from "views/Options/OptionsStore";
import FormsStore from "stores/FormsStore";
import AuthenticationStore from "stores/AuthenticationStore";

// ==== Api Handlers ====
import {
  getProfileById,
  getMasterDocument,
} from "apiHandlers/ProfileApiHandler";
import AccountOpeningApi from "apis/AccountOpeningApi";

// ==== Types ====
import { EOptionScreens } from "views/Options/types/optionsTypes";
import {
  ITermsAgreements,
  IAccountFeature,
  EOnboardingModelTypes,
  EOnboardingScreens,
} from "views/SignUp/Onboarding/types/onboardingTypes";
import { ACCOUNT_OPEN_STATUSES } from "views/SignUp/Onboarding/types/onboardingEnums";

// ==== Utilities ====
import { sleep } from "utilities/genericUtilities";
import { handleErrorTracking } from "utilities/apiUtilities";
import { pushToDataLayer } from "utilities/googleTagsUtilities";

const FILE_NAME = "AccountOpeningApiHandler";

export const postAccountType = async (accountType: number): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  try {
    FormsStore.setLoading(true);

    const response = await AccountOpeningApi.postAccountOpening(
      AuthenticationStore._tokenSource.token,
      OnboardingStore.profileId,
      accountType,
    );

    if (response) {
      OnboardingStore.setAccountOpening(response);
    } else {
      FormsStore.setErrorHandler("postAccountType");
    }
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "postAccountType");
  } finally {
    FormsStore.setLoading(false);
  }
};

export const postAccountOpeningUpdate = async (): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  try {
    FormsStore.setLoading(true);

    const response = await AccountOpeningApi.postAccountOpeningUpdate(
      AuthenticationStore._tokenSource.token,
      OnboardingStore.accountOpening,
    );

    if (response) {
      OnboardingStore.setAccountOpening(response);
    } else {
      FormsStore.setErrorHandler("postAccountType");
    }
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "postAccountType");
  } finally {
    FormsStore.setLoading(false);
  }
};

export const putTermsAgreements = async (
  documentName: string,
): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  try {
    FormsStore.setLoading(true);

    let pdfDoc = OnboardingStore.getPdfData(documentName);

    // First check to see if we have the PDF stored
    if (!pdfDoc) {
      // If we have not downloaded the doc's info yet, we need to do that first
      pdfDoc = await getMasterDocument(documentName);
    }

    if (pdfDoc) {
      // Second, we need to check if it's already accepted
      const termsAgreements = toJS(
        OnboardingStore.getAccountOpening(),
      )?.termsAgreements;

      // These are all the documents that the user has already accepted
      const filteredDocuments = termsAgreements.filter(
        (doc: ITermsAgreements) =>
          doc.documentName === documentName && doc.agreement,
      );

      // If they have already accepted, don't make a second API call
      if (filteredDocuments.length === 0) {
        const response = await AccountOpeningApi.putTermsAgreements(
          AuthenticationStore._tokenSource.token,
          {
            accountOpeningId: OnboardingStore.getAccountOpeningId(),
            agreement: true,
            documentName: pdfDoc.documentName,
            masterDocumentId: pdfDoc.id,
            version: pdfDoc.version,
          },
        );

        if (response) {
          await getProfileById();
        } else {
          FormsStore.setErrorHandler("putTermsAgreements");
        }
      }
    }
  } catch (e: unknown) {
    handleErrorTracking(e, FILE_NAME, "putTermsAgreements");
  } finally {
    FormsStore.setLoading(false);
  }
};

export const putAccountFeature = async (
  accountOpeningId: string,
  features: IAccountFeature,
): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  try {
    FormsStore.setLoading(true);

    const response = await AccountOpeningApi.putAccountFeature(
      AuthenticationStore._tokenSource.token,
      accountOpeningId,
      {
        id: OnboardingStore.getModelId(EOnboardingModelTypes.ACCOUNT_FEATURE),
        margin: features?.margin,
        noboOptOut: features?.noboOptOut,
        options: features?.options,
      },
    );

    if (response) {
      await getProfileById();
    } else {
      FormsStore.setErrorHandler("putAccountFeature");
    }
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "putAccountFeature");
  } finally {
    FormsStore.setLoading(false);
  }
};

export const getHasAccess = async (): Promise<boolean> => {
  AuthenticationStore.checkTokenSource();

  try {
    FormsStore.setLoading(true);

    return await AccountOpeningApi.getHasAccess(
      AuthenticationStore._tokenSource.token,
      OnboardingStore.getFormItemData(EOnboardingScreens.INDIVIDUAL, "Email"),
    );
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "getHasAccess");
  } finally {
    FormsStore.setLoading(false);
  }
};

export const getEligibleAccountOpeningType = async (): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  try {
    FormsStore.setLoading(true);

    const eligibleAccountTypes =
      await AccountOpeningApi.getEligibleAccountOpeningType(
        AuthenticationStore._tokenSource.token,
        OnboardingStore.profileId,
      );

    OnboardingStore.setEligibleAccountTypes(eligibleAccountTypes);
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "getEligibleAccountOpeningType");
  } finally {
    FormsStore.setLoading(false);
  }
};

export const putTradingLevel = async (): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  const tradingLevelData = OptionsStore.getFormData(
    EOptionScreens.OPTION_INVESTMENT_OBJECTIVES,
  );

  try {
    FormsStore.setLoading(true);

    const response = await AccountOpeningApi.postOptionsTradingLevel(
      AuthenticationStore._tokenSource.token,
      OnboardingStore.getAccountOpeningId(),
      get(tradingLevelData, "level"),
    );

    if (response) {
      await getProfileById();
    } else {
      FormsStore.setErrorHandler("putTradingLevel");
    }
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "putTradingLevel");
  } finally {
    FormsStore.setLoading(false);
  }
};

export const putInvestmentObjective = async (): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  const optionInvestmentData = OptionsStore.getFormData(
    EOptionScreens.OPTION_INVESTMENT_OBJECTIVES,
  );

  try {
    FormsStore.setLoading(true);

    const response = await AccountOpeningApi.postInvestmentObjective(
      AuthenticationStore._tokenSource.token,
      OnboardingStore.getAccountOpeningId(),
      get(optionInvestmentData, "investmentObjectives"),
    );

    if (response) {
      await getProfileById();
    } else {
      FormsStore.setErrorHandler("putInvestmentObjective");
    }
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "putInvestmentObjective");
  } finally {
    FormsStore.setLoading(false);
  }
};

export const postSubmitAccount = async (
  accountOpeningId: string,
): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  try {
    FormsStore.setLoading(true);
    OnboardingStore.setSubmitLoading(true);

    const response = await AccountOpeningApi.postAccountOpeningSubmit(
      AuthenticationStore._tokenSource.token,
      accountOpeningId,
    );

    if (response) {
      for (let retryAttempts = 0; retryAttempts < 10; retryAttempts++) {
        const accountStatus = OnboardingStore.getAccountOpening()?.status;

        if (
          accountStatus === ACCOUNT_OPEN_STATUSES.SUBMITTED.value ||
          accountStatus === ACCOUNT_OPEN_STATUSES.OPENED.value
        ) {
          // Account created! Create an event to track
          pushToDataLayer(EOnboardingScreens.SUCCESS, "onboardingSubmit");
          // Manually setting retryAttempts to 11 to break out of the loop
          retryAttempts = 11;
        } else {
          await getProfileById();
          await sleep(3000);
        }
      }

      const accountStatus = OnboardingStore.getAccountOpening()?.status;
      // Check for status error
      if (
        accountStatus !== ACCOUNT_OPEN_STATUSES.SUBMITTED.value ||
        accountStatus !== ACCOUNT_OPEN_STATUSES.OPENED.value
      ) {
        OnboardingStore.setErrorMaxRetryFailed(true);
      }
    } else {
      FormsStore.setErrorHandler("postSubmitAccount");
    }
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "postSubmitAccount");
  } finally {
    FormsStore.setLoading(false);
    OnboardingStore.setSubmitLoading(false);
  }
};
