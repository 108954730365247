import * as React from "react";
import { Form } from "@ant-design/compatible";
import { Spin, Checkbox, Button, Row } from "antd";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// ==== Stores ====
import FormsStore from "stores/FormsStore";
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";
import MarginStore from "views/Margin/MarginStore";

// ==== Components ====
import FormHeader from "components/FormHeader";
import FormButtonFooter from "views/SignUp/components/FormButtonFooter";

// ==== ApiHandlers ====
import { getMasterDocumentDownload } from "apiHandlers/ProfileApiHandler";

// ==== Types ====
import { EMarginScreens } from "views/Margin/types/marginTypes";
import { DOCUMENTS } from "views/SignUp/Onboarding/types/onboardingEnums";
import { EOnboardingForms } from "views/SignUp/Onboarding/types/onboardingTypes";
import { EAuthenticationScreens } from "types/authenticationTypes";
import { ERoutePaths } from "types/globalTypes";

const WelcomeForm: React.FunctionComponent = observer(() => {
  const { t } = useTranslation();
  const [marginAgreement, setMarginAgreement] = React.useState(false);
  const history = useHistory();

  React.useEffect(() => {
    if (!OnboardingStore.getAccountOpeningAccountNumber()) {
      history.push(ERoutePaths.ONBOARDING);
      FormsStore.setCurrentScreen(EAuthenticationScreens.ACCOUNT_SELECTION);
    }
  }, [history]);

  const getFormData = (key: string) =>
    OnboardingStore.getFormItemData(EOnboardingForms.INDIVIDUAL, key) || "";

  const downloadDirectMarginAgreement = async () => {
    await getMasterDocumentDownload(
      DOCUMENTS.DIRECT_MARGIN_AGREEMENT.documentName,
    );
  };

  const handleGoBackOnClick = () => {
    FormsStore.setCurrentScreen(EAuthenticationScreens.ACCOUNT_SELECTION);
  };

  const {
    isFileDownloading,
    getAccountOpeningAccountNumber,
    isAccountApproved,
  } = OnboardingStore;

  return (
    <React.Fragment>
      {getAccountOpeningAccountNumber() ? (
        <div className="slideInWrapper">
          <Form className="paddingBelow" layout="vertical">
            <FormHeader headerText={t("views.margin.tradingWithMargin")} />
            <div>
              <strong>
                {`${getFormData("FirstName")} ${getFormData("LastName")} - ${
                  !isAccountApproved()
                    ? `${t("views.margin.pendingAccount")} `
                    : ""
                }${getAccountOpeningAccountNumber()}`}
              </strong>
            </div>
            <div>
              <p>
                {t("views.margin.marginBodyText1")}
                {t("views.margin.marginBodyText1b")}
                <strong>{` ${t("views.margin.marginBodyText2")}`}</strong>
              </p>
            </div>
            <div>
              <p>{t("views.margin.marginAgreementBody")}</p>
            </div>
            <div className="centeredContent">
              <Row align="middle" justify="center">
                <Button onClick={downloadDirectMarginAgreement}>
                  {isFileDownloading ===
                    DOCUMENTS.DIRECT_MARGIN_AGREEMENT.documentName && (
                    <Spin size="small" style={{ paddingRight: 10 }} />
                  )}
                  {t("views.margin.reviewMarginAgreement")}
                </Button>
              </Row>
            </div>
            <Checkbox
              onChange={(value) => {
                // THIS IS A KNOWN BUG:
                // We need to update the IAccountFeature on the BE for it to be
                // updated on Beta...
                // BAs said it's fine.
                setMarginAgreement(value.target.checked);
              }}
            >
              <strong> {t("views.margin.agreeToTerms")}</strong>
            </Checkbox>
            <FormButtonFooter
              goForwardHandler={() =>
                MarginStore.validateMarginAgreementFormUpdate(
                  EMarginScreens.APPLICATION_STATUS,
                )
              }
              isGoForwardDisabled={!marginAgreement}
              goBackHandler={handleGoBackOnClick}
            />
          </Form>
        </div>
      ) : (
        <Spin spinning={true}>
          <div style={{ height: 200, width: 530 }} />
        </Spin>
      )}
    </React.Fragment>
  );
});

export default WelcomeForm;
